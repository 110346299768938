import Resource from 'components/global/Resource'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
// @ts-ignore
import Slide from 'react-reveal/Slide'

import LargeForm from '../components/forms/LargeForm'
import Modal from '../components/global/Modal'
import SectionTitle from '../components/global/SectionTitle'
import VideoEmbed from '../components/global/VideoEmbed'
import OverlayVideo from '../components/overlay-video'
import { H1, H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { Link } from 'gatsby'
import { Button } from '@arckit/ui'

export default function SummerPage() {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalTitle, setModalTitle] = useState('')

	const handleModalClose = () => setIsModalOpen(false)

	const handleModalOpen = (title: string) => {
		setIsModalOpen(true)
		setModalTitle(title)
	}
	return (
		<Layout pageName="Summer">
			<Section flatGray>
				<Row middle className="pb-12 pt-12 md:pb-16 md:pt-0">
					<Col width="w-full md:w-1/2">
						<Slide left cascade>
							<div>
								<H1 className="mb-3">Summer Learning Essentials</H1>
								<P>Engaging, content rich, user-friendly resources to support skill-building and learning in any environment.</P>
								<a href="https://mailchi.mp/b08d8fae86d2/g6xy8sgo7j" target="_blank" rel="noreferrer">
									<img src="/images/logos/esser-logo.png" className="block h-40 md:hidden" alt="esser logo" />
								</a>
							</div>
						</Slide>
					</Col>
					<Col width="w-full md:w-1/2" className="relative hidden md:block">
						<Slide right>
							<StaticImage src="../images/summer/summer-hero.png" placeholder="blurred" alt="kids reading" className="hidden sm:block" />
						</Slide>
						<a href="https://mailchi.mp/b08d8fae86d2/g6xy8sgo7j" target="_blank" rel="noreferrer">
							<img src="/images/logos/esser-logo.png" className="absolute h-48" style={{ bottom: 60, right: 60 }} alt="esser logo" />
						</a>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle title="High-Dosage Tutoring" />
				<Row middle className="flex-row-reverse" id="accelerator">
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full w-full bg-gray-200 py-16">
							<StaticImage src="../../static/images/summer/arc-accelerator.png" placeholder="blurred" alt="arc accelerator" className="-ml-0 md:-ml-32" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="pr-4 md:pr-32">
						<span className="mt-3 mb-1 inline-block text-3xl font-semibold">ARC Accelerator™</span>
						<P size="md" className="mb-4">
							Secondary and elementary intervention programs that help students build skills toward their grade level and beyond.
						</P>

						<Link to="/intervention#arc-accelerator">
							<Button>Learn More</Button>
						</Link>
					</Col>
				</Row>
				<Row middle className="mt-32">
					<Col width="w-full md:w-1/2">
						<div className="h-full w-full bg-gray-100 py-16 pl-0 md:pl-10">
							<StaticImage src="../../static/images/summer/toolkits-new.png" alt="toolkit products collage" className="-mr-0 md:-mr-32" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="pl-4 md:pl-40">
						<span className="mt-3 mb-1 inline-block text-3xl font-semibold">
							IRLA/ENIL Toolkits and Foundational Skills Toolkits<sup>®</sup>
						</span>
						<P size="md" className="mb-4">
							Enable differentiated, targeted, and efficient skills instruction in small groups, one-to-one tutoring, or summer settings. Available in Y–Pu in English
							and A–Bl in Spanish.
						</P>
						<Link to="/intervention">
							<Button>Learn More</Button>
						</Link>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle title="Take-Home Resources to Build Skills" />
				<Row middle className="mt-32 flex-row-reverse" id="practice-packs">
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full w-full bg-gray-200 py-16">
							<StaticImage src="../../static/images/summer/word-study-notebooks.png" placeholder="blurred" alt="practice packs" className="-ml-0 md:-ml-32" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="pr-4 md:pr-32">
						<span className="mt-3 mb-1 inline-block text-3xl font-semibold">Practice Packs</span>
						<P size="md" className="mb-4">
							Independent student activities to review essential reading skills, including phonics, decoding, encoding, and morphology, as well as math practice.
							Packs contain a student workbook and 3–5 skill-building books to add to home libraries. Available in English and Spanish.
						</P>
						<div className="flex items-center gap-3">
							<Resource title="Summer Practice Packs" linkOnly>
								<Button>See Pricing</Button>
							</Resource>

							<Button onClick={() => handleModalOpen('Practice Packs for Summer Learning')} variant="light">
								Learn More
							</Button>
						</div>
					</Col>
				</Row>
				<Row middle id="grab-and-go">
					<Col width="w-full md:w-1/2" className="mb-6 mt-32">
						<div className="h-full w-full bg-gray-200 py-16 pl-0 md:pl-10">
							<StaticImage src="../images/summer/grab-and-go.png" placeholder="blurred" alt="summer semester framework" className="-mr-0 md:-mr-32" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="pl-4 md:pl-40">
						<span className="mt-3 mb-1 inline-block text-3xl font-semibold">Grab & Go Packs</span>
						<P size="md" className="mb-4">
							Ensure students can continue learning and building knowledge about the world at home, during summer months. Perfect for all learners, including
							multilingual learners and those with limited or no internet access. Available for Grades PreK–8 in English and Grades PreK–5 in Spanish.
							<sup>*</sup>
						</P>
						<Resource title="Grab & Go Packs" linkOnly>
							<Button>Learn More</Button>
						</Resource>
					</Col>
				</Row>
				<Row middle className="mt-32 flex-row-reverse" id="take-home-kits">
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full w-full bg-gray-200 py-16">
							<StaticImage src="../images/summer/take-home-kits.png" placeholder="blurred" alt="summer take home kits" className="-ml-0 md:-ml-32" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="pr-4 md:pr-32">
						<span className="mt-3 mb-1 inline-block text-3xl font-semibold">Take-Home Kits</span>
						<P size="md" className="mb-4">
							Allow students to self-select their summer reading books in order to reduce screen time and combat summer slide and unfinished learning. Available for
							Grades K–12 in English, Spanish, and bilingual kits.
						</P>
						<Resource title="Take-Home Solutions" linkOnly>
							<Button>Learn More</Button>
						</Resource>
					</Col>
				</Row>
				<Row middle className="mt-32">
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full w-full bg-gray-200 py-16 pl-0 md:pl-10">
							<StaticImage src="../images/summer/research-quest.png" placeholder="blurred" alt="summer research quests" className="-mr-0 md:-mr-32" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="pl-4 md:pl-40">
						<span className="mt-3 mb-1 inline-block text-3xl font-semibold">Research Quests</span>
						<P size="md" className="mb-4">
							Includes four-week knowledge-building units with corresponding eLibraries, designed for both synchronous and asynchronous learning environments.
							Available for Grades K–5 in English and Spanish.
						</P>
						<Resource title="Schoolpace Connect Research Quest" linkOnly>
							<Button>Learn More</Button>
						</Resource>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20" id="summer-semester">
				<SectionTitle title="High-Quality Summer Curriculum" />
				<Row middle>
					<Col width="w-full md:w-1/2" className="pr-4 md:pr-20">
						<H3 className="mb-4">Summer Semester</H3>
						<P>
							Summer Semester is an English Language Arts curriculum specifically designed to extend learning into summer. Perfect for programs that are focused on
							building knowledge, these resources ensure ease of use for instructors. Content can be delivered in a traditional classroom, local community center, or
							outside in nature. With Summer Semester, students will become:
						</P>
						<ul className="list-checkmark my-10 pl-16 text-xl text-gray-600">
							<li className="mb-4">Experts on a topic of their choice through daily research</li>
							<li className="mb-4">Avid readers through engaging independent reading</li>
							<li>Authors as they edit, revise, and publish their own informational text</li>
						</ul>
						<div className="flex">
							<div className="mb-3 pr-3">
								<Resource title="Available Summer Themes" linkOnly>
									<Button size="lg">Explore all available themes</Button>
								</Resource>
							</div>
						</div>
					</Col>
					<Col width="md:w-1/2" className="hidden md:block">
						<div className="h-full w-full bg-gray-200 py-16 pl-10 pr-10">
							<StaticImage src="../images/summer/summer-semester-framework.png" placeholder="blurred" alt="summer semester framework" className="-ml-0 md:-ml-32" />
						</div>
					</Col>
				</Row>
			</Section>
			{/* <Section margin="mt-20 md:mt-40">
				<SectionTitle
					title={[
						<span key="sectionTitleH2Span" className="mx-auto block max-w-2xl">
							Make Summer Learning Easy with SchoolPace Connect<sup>®</sup>
						</span>
					]}
					text={[
						<span key="sectionTitlePWrapper">
							<span key="sectionTitlePSpan" className="mb-0 block text-xl">
								An enhanced platform for ELA/SLA content delivery for Grades K–5 that integrates seamlessly with any existing district Learning Management System.
							</span>
							<span key="sectionTitleDisclaimerSpan" className="text-xs">
								Requires a SchoolPace Connect Subscription
							</span>
						</span>
					]}
				/>
				<Row middle>
					<Col>
						<ForTabs />
					</Col>
				</Row>
			</Section>

			<Section margin="mt-20">
				<Row>
					<Col>
						<ReviewsResults />
					</Col>
				</Row>
			</Section> */}
			<Section margin="mt-20">
				<SectionTitle
					title="Watch It in Action"
					text="Learn more about how ARC Summer Semester creates independent, engaged learners whose final projects showcase both content knowledge and ELA skills."
				/>
				<Row>
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="relative w-full">
							<button
								className="absolute top-1/2 left-1/2 z-50 text-white opacity-75 hover:opacity-100 focus:outline-none"
								style={{ transform: 'translate(-50%, -50%)' }}
								onClick={() => handleModalOpen('Summer Semester Inspires Inquiry in Young Scientists')}
							>
								<svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" viewBox="0 0 20 20" fill="currentColor">
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
										clipRule="evenodd"
									/>
								</svg>
							</button>
							<VideoEmbed src="https://player.vimeo.com/video/664371446" title="Summer Semester Inspires Inquiry in Young Scientists" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="relative w-full">
							<button
								className="absolute top-1/2 left-1/2 z-50 text-white opacity-75 hover:opacity-100 focus:outline-none"
								style={{ transform: 'translate(-50%, -50%)' }}
								onClick={() => handleModalOpen('Teaching with the End in Mind')}
							>
								<svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" viewBox="0 0 20 20" fill="currentColor">
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
										clipRule="evenodd"
									/>
								</svg>
							</button>
							<VideoEmbed src="https://player.vimeo.com/video/664371731" title="Teaching with the End in Mind" />
						</div>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="Summer Semester" showSummerPricingGuide />
					</Col>
				</Row>
			</Section>
			<Section>
				<Row className="py-10">
					<Col>
						<p className="text-center text-sm font-light">* Our evidence-based solutions are eligible for various federal, state, and local funding programs.</p>
					</Col>
				</Row>
			</Section>
			<Modal title={modalTitle} isOpen={isModalOpen} handleClose={handleModalClose} wide>
				<OverlayVideo title={modalTitle} autoPlay />
			</Modal>
		</Layout>
	)
}
